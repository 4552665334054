<template>
  <ion-col
    class="left padding-32"
    size-lg="5"
    size-md="6"
    size-sm="12"
    size-xs="12"
  >
    <a href="." class="logo"
      ><img src="@/theme/img/logo.png" alt="Logo BMKG" width="40" height="49"
    /></a>
    <div class="ion-align-self-center">
      <ion-text color="ungu">
        <h2>Selamat Datang di Portal BMKG.</h2>
        <p class="margin-y-32 intro">
          Portal BMKG merupakan sistem berbasis Single Sing-On yang ditujukan
          bagi para pegawai BMKG agar dapat menggunakan layanan sistem informasi
          di lingkungan BMKG hanya dengan satu akun.
        </p>
      </ion-text>
      <ion-button
        class="ion-margin-top ion-margin-bottom"
        color="ungu"
        expand="block"
        shape="round"
        @click="$keycloak.loginFn"
        >Masuk</ion-button
      >
    </div>
    <p class="margin-bottom-0 copyright">
      <small>2023 Pusat Jaringan Komunikasi BMKG</small>
    </p>
  </ion-col>
  <ion-col
    class="right branding padding-32"
    size-lg="7"
    size-md="6"
    size-sm="12"
    size-xs="12"
  >
    <ion-text color="ungu">
      <h1>Portal BMKG<br />Single Sign-On.</h1>
      <p class="tagline">
        Masuk ke berbagai layanan sistem informasi di BMKG dengan satu akun.
      </p>
    </ion-text>
  </ion-col>
</template>

<script>
import { defineComponent } from "vue";
import { IonCol, IonText, IonButton } from "@ionic/vue";

export default defineComponent({
  components: {
    IonCol,
    IonText,
    IonButton,
  },
  setup() {},
});
</script>

<style scoped>
.intro {
  display: inline-block;
  font-size: 1.1rem;
}
.tagline {
  display: inline-block;
  font-size: 1.3rem;
  font-weight: 500;
}
.landing .left {
  display: flex;
}
.landing .logo {
  position: absolute;
}
.landing .copyright {
  bottom: 32px;
  position: absolute;
}
.branding {
  background: transparent;
  background-image: url("@/theme/img/imac.png"), url("@/theme/img/calendar.png"),
    url("@/theme/img/app.png"), url("@/theme/img/graph.png");
  background-position: 90% 30%, 50% 0, 10% 20%, 20% 50%;
  background-repeat: no-repeat;
  background-size: 50%, 25%, 25%, 25%;
  border-radius: 0 30px 30px 0;
  padding-top: 400px;
}
@media screen and (max-width: 575px) {
  #container::before {
    background: rgba(255, 255, 255, 0.1);
    content: "";
    height: 100%;
    position: absolute;
    width: 100%;
  }
  .landing .left {
    background-image: radial-gradient(
        circle,
        rgba(255, 255, 255, 50%),
        rgba(255, 255, 255, 100%)
      ),
      url("@/theme/img/eclipse.png");
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .landing .left {
    border-radius: 30px;
  }
}
@media screen and (max-width: 767px) {
  .landing .left {
    display: inline;
  }
  .landing .logo {
    display: block;
    position: inherit;
    text-align: center;
  }
  .landing .copyright {
    bottom: 32px;
    position: inherit;
  }
  .branding {
    display: none;
  }
}
</style>
