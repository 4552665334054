<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-grid id="container" :fixed="true">
        <ion-row
          class="ion-justify-content-center"
          v-if="$keycloak.authenticated"
        >
          <HomePage />
        </ion-row>
        <ion-row class="landing ion-justify-content-center" v-else>
          <LandingPage />
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import { IonPage, IonContent, IonGrid, IonRow } from "@ionic/vue";
import LandingPage from "./LandingPage.vue";
import HomePage from "./HomePage.vue";

export default defineComponent({
  name: "ContainerPage",
  components: {
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    LandingPage,
    HomePage,
  },
});
</script>

<style>
a {
  text-decoration: none;
}
h1 {
  color: var(--ion-color-ungu-shade) !important;
  font-size: 2.5rem !important;
  font-weight: 700 !important;
}
h2 {
  color: var(--ion-color-ungu-shade) !important;
  font-weight: 700 !important;
}
.margin-bottom-4 {
  margin-bottom: 4px !important;
}
.margin-bottom-0 {
  margin-bottom: 0;
}
.margin-y-32 {
  margin: 32px 0;
}
.padding-32 {
  padding: 32px;
}
#container {
  background-color: #e3e0ff;
  background-image: url("@/theme/img/eclipse.png");
  background-position: 75% 0;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 30px;
  box-shadow: 0 0 30px rgb(0 0 0 / 20%);
  margin: 50px auto;
}
.logo {
  text-align: left;
  display: inline-block;
}
.logo img {
  height: auto;
}
ion-grid {
  --ion-grid-width: 960px;
  --ion-grid-padding: 0;
  --ion-grid-width-xs: 100%;
  --ion-grid-width-sm: 540px;
  --ion-grid-width-md: 720px;
}
.left {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 30px 0 0 30px;
}
.copyright {
  color: #6b67aa;
  display: inline-block;
}
@media screen and (max-width: 575px) {
  #container {
    border-radius: 0;
    margin: 0;
  }
  .left {
    border-radius: 0;
  }
  .landing .left {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .left {
    border-radius: 30px 30px 0 0;
  }
}
@media screen and (max-width: 767px) {
  h2 {
    text-align: center;
  }
  .copyright {
    display: block;
    text-align: center;
  }
}
</style>
