<template>
  <ion-col
    class="left padding-32"
    size-lg="5"
    size-md="6"
    size-sm="12"
    size-xs="12"
  >
    <a href="." class="logo"
      ><img src="@/theme/img/logo.png" alt="Logo BMKG" width="40" height="49"
    /></a>
    <div class="avatar">
      <img
        @src="
          'https://simas.bmkg.go.id/app/client/bmkg/uploads/siap/foto/' +
            $keycloak.idTokenParsed.preferred_username +
            '.jpg'
        "
        width="160"
        height="160"
        alt="Gambar Profil"
      />
    </div>
    <div class="profile">
      <ion-list lines="none">
        <ion-item>
          <ion-icon :icon="cardOutline" slot="start"></ion-icon>
          <ion-label>
            <p class="margin-bottom-4">
              <span class="title">NIP</span>
            </p>
            <code>{{ $keycloak.idTokenParsed.preferred_username }}</code>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-icon :icon="personOutline" slot="start"></ion-icon>
          <ion-label>
            <p class="margin-bottom-4">
              <span class="title">Nama</span>
            </p>
            {{ $keycloak.idTokenParsed.name }}
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-icon :icon="atCircleOutline" slot="start"></ion-icon>
          <ion-label>
            <p class="margin-bottom-4">
              <span class="title">Surel BMKG</span>
            </p>
            {{ $keycloak.idTokenParsed.email }}
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-icon :icon="atCircle" slot="start"></ion-icon>
          <ion-label>
            <p class="margin-bottom-4">
              <span class="title">Surel Pemulihan</span>
            </p>
            {{ $keycloak.idTokenParsed.recovery_email }}
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-icon :icon="callOutline" slot="start"></ion-icon>
          <ion-label>
            <p class="margin-bottom-4">
              <span class="title">Nomor Telepon</span>
            </p>
            <code>{{ $keycloak.idTokenParsed.phone_number }}</code>
          </ion-label>
        </ion-item>
        <ion-item href="#">
          <ion-icon :icon="lockOpenOutline" slot="start"></ion-icon>
          <ion-label>Ganti Kata Sandi</ion-label>
        </ion-item>
        <ion-item href="#">
          <ion-icon :icon="qrCodeOutline" slot="start"></ion-icon>
          <ion-label>Atur OTP</ion-label>
        </ion-item>
      </ion-list>
    </div>
    <ion-button
      class="ion-margin-top ion-margin-bottom logout"
      color="ungu"
      expand="block"
      shape="round"
      @click="$keycloak.logoutFn"
      >Keluar</ion-button
    >
    <p class="margin-bottom-0 copyright">
      <small>2023 Pusat Jaringan Komunikasi BMKG</small>
    </p>
  </ion-col>
  <ion-col
    class="right padding-32"
    size-lg="7"
    size-md="6"
    size-sm="12"
    size-xs="12"
  >
    <ion-text color="ungu">
      <h2>Sistem Informasi SSO</h2>
    </ion-text>
    <code>{{ $keycloak.idTokenParsed }}</code>
    <ion-grid>
      <ion-row class="ion-justify-content-center">
        <ion-col
          v-for="d in data"
          :key="d.id"
          class="ion-padding"
          size-lg="4"
          size-md="6"
          size-sm="4"
          size-xs="6"
        >
          <div class="app ion-padding">
            <a :href="d.url" target="_blank">
              <img :src="require(`@/theme/img/${d.img}`)" :alt="d.name"
            /></a>
          </div>
          <p class="app-title ion-no-margin">
            <a :href="d.url" target="_blank">{{ d.name }}</a>
          </p>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-button
      class="ion-margin-top ion-margin-bottom logout-bottom"
      color="ungu"
      expand="block"
      shape="round"
      @click="$keycloak.logoutFn"
      >Keluar</ion-button
    >
    <p class="margin-bottom-0 copyright bottom">
      <small>2023 Pusat Jaringan Komunikasi BMKG</small>
    </p>
  </ion-col>
</template>

<script>
import { defineComponent, ref } from "vue";
import {
  IonButton,
  IonCol,
  IonGrid,
  IonRow,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonText,
} from "@ionic/vue";
import {
  cardOutline,
  personOutline,
  atCircle,
  atCircleOutline,
  callOutline,
  lockOpenOutline,
  qrCodeOutline,
} from "ionicons/icons";

export default defineComponent({
  components: {
    IonButton,
    IonCol,
    IonGrid,
    IonRow,
    IonList,
    IonItem,
    IonLabel,
    IonIcon,
    IonText,
  },
  setup() {
    const data = ref([
      {
        id: 1,
        name: "Surel BMKG (Google)",
        url: "https://mail.google.com/a/bmkg.go.id",
        img: "gws.png",
      },
      {
        id: 2,
        name: "Eoffice BMKG",
        url: "https://eoffice.bmkg.go.id",
        img: "eoffice.png",
      },
      {
        id: 3,
        name: "Aplikasi BMKG",
        url: "https://www.bmkg.go.id",
        img: "draft-apps.svg",
      },
    ]);
    return {
      cardOutline,
      personOutline,
      atCircle,
      atCircleOutline,
      callOutline,
      lockOpenOutline,
      qrCodeOutline,
      data,
    };
  },
});
</script>

<style scoped>
.avatar {
  background-image: linear-gradient(
    45deg,
    rgba(107, 212, 255, 0.25),
    rgba(107, 103, 170, 0.25)
  );
  border-radius: 50%;
  display: block;
  height: 160px;
  margin: 16px auto;
  width: 160px;
}
.avatar img {
  border-radius: 50%;
  object-fit: cover;
  object-position: center center;
  padding: 20px;
}
ion-item {
  --background: transparent;
  --color: var(--ion-color-ungu-shade);
  --padding-start: 0;
  --padding-end: 0;
  font-size: 1rem;
  font-weight: 500;
}
ion-item ion-icon {
  margin-right: 16px;
}
.list-ios {
  background: transparent;
}
.title {
  color: #6b67aa;
  font-size: 0.925rem;
}
.app a {
  background-image: linear-gradient(45deg, #e3e0ff, #fff);
  border-radius: 50%;
  display: table-cell;
  height: auto;
  padding: 20px;
  width: 100%;
}
.app img {
  vertical-align: middle;
}
.app-title {
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
}
.app-title a {
  color: var(--ion-color-ungu-shade);
}
@media screen and (max-width: 575px) {
  .logout,
  .copyright {
    display: none;
  }
  .logout-bottom,
  .copyright.bottom {
    display: block;
  }
}
@media screen and (min-width: 576px) {
  .logout,
  .copyright {
    display: block;
  }
  .logout-bottom,
  .copyright.bottom {
    display: none;
  }
}
</style>
