import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueKeycloakJs from "@dsb-norge/vue-keycloak-js";

import { IonicVue } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";

const app = createApp(App)
  .use(IonicVue, {
    rippleEffect: false,
    mode: "ios",
  })
  .use(router)
  .use(VueKeycloakJs, {
    init: {
      // Use 'login-required' to always require authentication
      // If using 'login-required', there is no need for the router guards in router.js
      onLoad: "check-sso",
      silentCheckSsoRedirectUri:
        window.location.origin + "/silent-check-sso.html",
    },
    config: {
      url: "https://gapura.bmkg.go.id/auth",
      clientId: "gapura-sso",
      realm: "gapura",
    },
    onReady(keycloak) {
      console.log("Keycloak ready", keycloak);
    },
  });

router.isReady().then(() => {
  app.mount("#app");
});
